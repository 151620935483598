export default class SpeakerRequestHandler {
  callbacks = {}

  init (vue) {
    this.$fireBase = vue.$fireBase
    this.$store = vue.$store
    this.$enums = vue.$enums
    this.$toastr = vue.$toastr

    // "vue" property is required in order to use translation (this.vue.$t)
    this.vue = vue
  }

  /**
   * Adds a speaker request of type "live"
   */
  addLiveRequest () {
    this.addRequest(this.$enums.actions.LIVE)
  }

  /**
   * Adds a forced pending live speaker request
   *
   * @param {int} speakerExternalId
   */
  addForcedLiveRequest (speakerExternalId) {
    this.$fireBase.addSpeakerRequest(speakerExternalId, this.$enums.actions.LIVE, this.$enums.actions.PENDING, this.$store.state.user.externalId)

    this.$toastr.s(this.vue.$t('toastr.requestSend'))
  }

  /**
   * Adds a speaker request of type "document"
   */
  addDocumentRequest (callback) {
    this.callbacks[this.$enums.actions.DOCUMENT] = callback
    this.addRequest(this.$enums.actions.DOCUMENT)
  }

  /**
   * Adds a speaker request of type "screen sharing"
   */
  addScreenSharingRequest () {
    this.addRequest(this.$enums.actions.SCREEN_SHARING)
  }

  /**
   * Remove the "screen sharing" speaker request
   */
  removeCurrentSpeakerRequest () {
    this.$fireBase.removeSpeakerRequest(this.$store.state.user.externalId)
  }

  /**
   * Accepts speaker request
   *
   * @param {int} speakerExternalId
   */
  accept (speakerExternalId) {
    this.$fireBase.updateSpeakerRequestStatus(speakerExternalId, this.$enums.actions.ACCEPTED)
  }

  /**
   * Refuses speaker request
   *
   * @param {int} speakerExternalId
   */
  refuse (speakerExternalId) {
    this.$fireBase.updateSpeakerRequestStatus(speakerExternalId, this.$enums.actions.REFUSED)
  }

  /**
   * Called when one (or more) speaker request changes
   *
   * @param array oldSpeakerRequests
   * @param array speakerRequests
   */
  onSpeakerRequestChange (oldSpeakerRequests, speakerRequests) {
    if (!this.$store.getters['user/isAdminOrManager']) {
      return
    }

    let speakerNotificationPendingCount = 0

    Object.entries(speakerRequests).forEach(([speakerExternalId, speakerRequest]) => {
      // Stop if current user is the author of the request or the update
      if (speakerExternalId === this.$store.state.user.externalId || speakerRequest.statusUpdatedBy === this.$store.state.user.externalId) {
        return
      }

      // No notification for forced request
      if (Object.keys(speakerRequest).indexOf('forcedBy') > -1) {
        return
      }

      if (speakerRequest.status === this.$enums.actions.PENDING) {
        speakerNotificationPendingCount++
      }

      // Display notification only if status has changed
      if (oldSpeakerRequests[speakerExternalId] && oldSpeakerRequests[speakerExternalId].status !== speakerRequest.status) {
        const requestorName = this.$store.getters['team/getSpeakerFullName'](speakerExternalId)
        const updatorName = this.$store.getters['team/getSpeakerFullName'](speakerRequest.statusUpdatedBy)

        // Reset "speakerRequestAuthor" to hide the confirmation modale if this speaker request was accepted/refused
        const speakerRequestAuthor = this.$store.getters['actions/getSpeakerRequestAuthor']
        if (speakerRequestAuthor && String(speakerRequestAuthor.externalId) === String(speakerExternalId)) {
          this.$store.commit('actions/setSpeakerRequestAuthor', null)
        }

        if (speakerRequest.status === this.$enums.actions.ACCEPTED) {
          this.$toastr.s(this.vue.$t('toastr.requestByAccceptedBy', {
            requestedBy: requestorName,
            acceptedBy: updatorName
          }))
        } else {
          this.$toastr.e(this.vue.$t('toastr.requestByRefusedBy', {
            requestedBy: requestorName,
            refusedBy: updatorName
          }))
        }
      }
    })

    // Display speaker notification
    this.$store.commit('notifications/resetSpeakerNotificationPending')
    for (let i = 0; i < speakerNotificationPendingCount; i = i + 1) {
      this.$store.commit('notifications/incrementSpeakerNotificationPending')
    }
  }

  /**
   * Called when the request created by the current user changes
   *
   * @param {object} speakerRequest
   */
  onCurrentSpeakerRequestChange (speakerRequest) {
    const isForcedRequest = Object.keys(speakerRequest).indexOf('forcedBy') > -1

    if (speakerRequest.status === this.$enums.actions.ACCEPTED) {
      this.onCurrentSpeakerRequestAccepted(speakerRequest)
    } else if (speakerRequest.status === this.$enums.actions.REFUSED) {
      this.onCurrentSpeakerRequestRefused(speakerRequest)
    } else if (speakerRequest.status === this.$enums.actions.PENDING && isForcedRequest) {
      this.$store.commit('actions/setSpeakerRequestAuthor', this.$store.state.user)
    }
  }

  /**
   * Called when the request created by the current user is accepted
   *
   * @param {object} speakerRequest
   */
  onCurrentSpeakerRequestAccepted (speakerRequest) {
    const isForcedRequest = Object.keys(speakerRequest).indexOf('forcedBy') > -1
    if (!isForcedRequest || (isForcedRequest && speakerRequest.forcedBy === this.$store.state.user.externalId)) {
      this.$toastr.s(this.vue.$t('toastr.requestAccepted'))
    }
    this.removeCurrentSpeakerRequest()

    // Live
    if (speakerRequest.type === this.$enums.actions.LIVE) {
      this.$fireBase.addSpeakerOnLive(this.$store.state.user.externalId)

      if (this.$store.state.actions.requestedWithAudio) {
        this.$store.commit('tokbox/setAudio', true)
      }

      if (this.$store.state.actions.requestedWithCamera) {
        this.$store.commit('tokbox/setCamera', true)
      }
    }

    // Screen sharing
    if (speakerRequest.type === this.$enums.actions.SCREEN_SHARING) {
      this.$fireBase.setCurrentBroadcastSharing(this.$store.state.user.externalId, this.$enums.actions.SCREEN_SHARING, null, null)
    }

    // Call callback
    if (this.callbacks[speakerRequest.type]) {
      this.callbacks[speakerRequest.type]()
      this.callbacks[speakerRequest.type] = null
    }

    // Re-init requestedWith*
    this.$store.commit('actions/setRequestedWithCamera', false)
    this.$store.commit('actions/setRequestedWithAudio', false)
  }

  /**
   * Called when the request created by the current user is refused
   *
   * @param {object} speakerRequest
   */
  onCurrentSpeakerRequestRefused (speakerRequest) {
    if (this.callbacks[speakerRequest.type]) this.callbacks[speakerRequest.type] = null
    this.$toastr.e(this.vue.$t('toastr.requestRefused'))
    this.removeCurrentSpeakerRequest()

    // Re-init requestedWith*
    this.$store.commit('actions/setRequestedWithCamera', false)
    this.$store.commit('actions/setRequestedWithAudio', false)
  }

  /**
   * Returns "true" if current user is allowed to confirm speaker requests, otherwise returns "false"
   *
   * @returns {bool}
   */
  canConfirm () {
    return this.$store.getters['user/isAdminOrManager']
  }

  /**
   * Adds a pending speaker request
   *
   * @param {string} type
   */
  addRequest (type) {
    this.$fireBase.addSpeakerRequest(this.$store.state.user.externalId, type, this.$enums.actions.PENDING, null)

    this.$toastr.s(this.vue.$t('toastr.requestSend'))
  }
}
