export default {
  namespaced: true,
  state: {
    testLive: false,
    testLiveDuration: 60,
    testLiveTime: 0,
    testLiveClose: true,
    live: false,
    liveTime: 0,
    liveClose: true,
    confirmAction: false,
    replayTime: 0,
    infoTestLive: true,
    infoTestLiveText1: 1,
    infoTestLiveText2: 2,
    showLive: false
  },
  mutations: {
    setTestLive (state, testLive) {
      state.testLive = testLive
    },
    setTestLiveTime (state, testLiveTime) {
      state.testLiveTime = testLiveTime
    },
    setTestLiveClose (state, testLiveClose) {
      state.testLiveClose = testLiveClose
    },
    setLive (state, live) {
      state.live = live
    },
    setLiveTime (state, liveTime) {
      state.liveTime = liveTime
    },
    setLiveClose (state, liveClose) {
      state.liveClose = liveClose
    },
    setConfirmAction (state, confirmAction) {
      state.confirmAction = confirmAction
    },
    setReplayTime (state, replayTime) {
      state.replayTime = replayTime
    },
    setInfoTestLive (state, infoTestLive) {
      state.infoTestLive = infoTestLive
    },
    setInfoTestLiveText (state, data) {
      if (data.infoTestLiveText1) {
        state.infoTestLiveText1 = data.infoTestLiveText1
      } else {
        state.infoTestLiveText1 = ''
      }
      if (data.infoTestLiveText2) {
        state.infoTestLiveText2 = data.infoTestLiveText2
      } else {
        state.infoTestLiveText2 = ''
      }
    },
    setShowLive (state, showLive) {
      state.showLive = showLive
    }
  }
}
