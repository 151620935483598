export default Object.freeze({
  DRAFT: 'draft',
  DRAFT_BROADCAST_NOT_STARTED: 'draft_broadcast_not_started',
  DRAFT_BROADCAST_STARTED: 'draft_broadcast_started',
  DRAFT_BROADCAST_STOPPED: 'draft_broadcast_stopped',
  DRAFT_BROADCAST_ENDED: 'draft_broadcast_ended',
  LIVE: 'live',
  LIVE_BROADCAST_STARTED: 'live_broadcast_started',
  LIVE_BROADCAST_ENDED: 'live_broadcast_ended',
  REPLAY: 'replay',
  REPLAY_VIDEO_NOT_PLAYING: 'replay_video_not_playing',
  REPLAY_VIDEO_PLAYING: 'replay_video_playing'
})
