// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

import VTooltip from 'v-tooltip'
import Toastr from 'vue-toastr'
import 'vue-toastr/src/vue-toastr.scss'
import SocialSharing from 'vue-social-sharing'
import linkify from 'vue-linkify'

import App from './App'

import store from 'plugins/store'
import { i18n, availableLocale, loadLanguageAsync } from 'plugins/i18n'
import enums from 'plugins/enums'
import router from 'plugins/router'

import formatDuration from 'filters/formatDuration'

import { firebaseSingleton } from 'class/FireBase'
import SpeakerRequestHandler from 'class/SpeakerRequestHandler'
import BroadcastHandler from './class/BroadcastHandler'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

const debug = import.meta.env.VITE_DEBUG

// Get from browser
const browserLocales = navigator.languages.map((l) => l.split('-')[0])
// Remove localStorage locale if it no more supported
if (!availableLocale.includes(localStorage.getItem('i18n'))) {
  localStorage.removeItem('i18n')
}
// get from localStorage or take first language matching available locales or fallback to fr 
const wantedLocale = localStorage.getItem('i18n') ?? browserLocales.find((bl) => availableLocale.includes(bl))  ?? 'fr'
loadLanguageAsync(wantedLocale)

Vue.config.debug = debug
Vue.config.productionTip = !debug // Ne plus avoir la notification de production au démarrage de Vue.
Vue.config.silent = !debug // Supprime tous les logs et warnings de Vue.js.
Vue.config.devtools = debug // Autorise ou non l’inspection des vue-devtools.
Vue.config.performance = debug // activer le suivi des performances pour l’initialisation, la compilation, le rendu et la mise à jour des composants.

Vue.prototype.$enums = enums
Vue.prototype.$fireBase = firebaseSingleton
Vue.prototype.$speakerRequestHandler = new SpeakerRequestHandler()
Vue.prototype.$broadcastHandler = new BroadcastHandler()
Vue.prototype.$log = debug ? console.log : null

Vue.prototype.$error = function (err) {
  if (import.meta.env.VITE_DEBUG) {
    this.$toastr.e(err)
    console.error(err)
  } else {
    this.$toastr.e('Une erreur est survenue')
    console.error(err)
  }
}

Vue.prototype.$deleteFirstLoader = () => {
  const firstLoader = document.getElementById('firstLoader')
  if (typeof firstLoader === 'undefined' || firstLoader === null) return
  firstLoader.parentNode.removeChild(firstLoader)
}

library.add(faPhone)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VTooltip)

Vue.use(Toastr, {
  clickClose: true,
  debug: import.meta.env.VITE_DEBUG,
  newestOnTop: false,
  progressBar: true,
  defaultPosition: 'toast-top-center',
  preventDuplicates: false,
  showDuration: 300,
  hideDuration: 1000,
  timeOut: 10000,
  extendedTimeOut: 10000,
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut'
})
Vue.use(SocialSharing)

Vue.directive('linkified', linkify)

Vue.filter('formatDuration', formatDuration)

const vue = new Vue({
  render: (h) => h(App),
  router,
  i18n,
  store
}).$mount('#app')
  
  
vue.$speakerRequestHandler.init(vue)
vue.$broadcastHandler.init(vue)


