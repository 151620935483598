export default Object.freeze({
  CAMERA: 'camera',
  SCREEN: 'screen',
  APPLICATION: 'application',
  WINDOW: 'window',
  IMAGE: 'image',
  VIDEO: 'video',
  DOC: 'doc',
  SHARE: 'share',
  ERRORS: {
    USER_DENIED_ACCESS: 'OT_USER_MEDIA_ACCESS_DENIED'
  }
})
