import endpoints from 'plugins/declarations/endpoints'
import store from 'plugins/store'
import Request from 'class/Request'
import Vue from 'vue'
import enums from 'plugins/enums'

export default {
  namespaced: true,
  state: {
    sessionId: null,
    tokenTokbox: null,
    token: null,
    systemCheckToken: null,
    systemCheckSessionId: null,
    landing: true,
    systemCheck: false,
    parametersCheck: false,
    onAir: false,
    recording: false,
    recordingTime: 0,
    authErrorCode: 0
  },
  mutations: {
    setSessionTokbox (state, data) {
      state.tokenTokbox = data.token
      state.sessionId = data.sessionId
      state.systemCheckToken = data.systemCheckToken
      state.systemCheckSessionId = data.systemCheckSessionId
    },
    setLanding (state, access) {
      state.landing = access
    },
    setSystemCheck (state, check) {
      state.systemCheck = check
    },
    setParametersCheck (state, check) {
      state.parametersCheck = check
    },
    setToken (state, token) {
      Request.defaults.headers['Authorization'] = 'Bearer ' + token
      state.token = token
    },
    deleteSession (state) {
      state.token = null
      state.room = false
      state.systemCheck = false
      state.parametersCheck = false
    },
    setOnAir (state, active) {
      state.onAir = active
    },
    setRecording (state, active) {
      state.recording = active
    },
    setRecordingTime (state, time) {
      state.recordingTime = time
    },
    setTokenTokbox (state, data) {
      state.tokenTokbox = data.tokboxToken
    },
    setAuthErrorCode (state, authErrorCode) {
      state.authErrorCode = authErrorCode
    }
  },
  actions: {
    authenticate (context, to) {
      const tokenAPI = to.params.token
      return Request
        .get(endpoints.LIVE_AUTHENTICATE + '/' + tokenAPI)
        .then((response) => {
          const currentUser = response.data.data

          return Vue.prototype.$fireBase
            .authenticate(currentUser.fact)
            .then(() => {
              return response
            })
        })
        .then((response) => {
          const room = response.data.data.room
          const currentUser = response.data.data
          const userType = currentUser.role === enums.roles.SPECTATOR ? enums.roles.SPECTATOR : enums.roles.SPEAKER

          // on veut récupérer le tree de présénce de tous les speakers
          let pathRefToListen = '/rooms/' + room.externalId + '/presence/speakers'

          if (userType === enums.roles.SPECTATOR) {
            // on ne veut récupérer que la présence du spectator qui essaye de s'authentifier
            pathRefToListen = '/rooms/' + room.externalId + '/presence/spectators/' + currentUser.externalId
          }

          return Vue.prototype.$fireBase
            .database
            .ref(pathRefToListen)
            .once('value')
            .then((snapshot) => {
              const snapshotValue = snapshot.val()

              // snapshotValue null = pas de data sur le path que l'on requete
              if (!snapshotValue) {
                return response
              }

              if (userType === enums.roles.SPECTATOR) {
                if (import.meta.env.VITE_AUTH_LIMIT_SPECTATOR === false) {
                  return response
                }

                // si on est ici c'est que la snapshotValue n'est pas null --> donc le spectator est déjà authentifié --> 403
                context.commit('setAuthErrorCode', 1)

                throw new Error('[session/authenticate] Spectator externalId ' + currentUser.externalId + ' is already connected.')
              } else if (userType === enums.roles.SPEAKER) {
                if (import.meta.env.VITE_AUTH_LIMIT_SPEAKER === false) {
                  return response
                }

                // un speaker est déjà connecté avec cet externalId --> 403
                if (snapshotValue.hasOwnProperty(currentUser.externalId)) {
                  context.commit('setAuthErrorCode', 2)
                  context.commit('setToken', tokenAPI)

                  store.commit('room/setUpdate', response.data.data.room)
                  store.commit('user/setUpdate', response.data.data)

                  throw new Error('[session/authenticate] Speaker externalId ' + currentUser.externalId + ' is already connected.')
                }

                const speakersRolesCounters = {
                  speaker: {
                    limit: room.broadcastType === 'studio' ? Number.POSITIVE_INFINITY : 3,
                    counter: 0
                  },
                  manager: {
                    limit: 1,
                    counter: 0
                  },
                  moderator: {
                    limit: room.broadcastType === 'studio' ? Number.POSITIVE_INFINITY : 1,
                    counter: 0
                  },
                  admin: {
                    limit: 5,
                    counter: 0
                  }
                }

                for (let onlineSpeakerExternalId in snapshotValue) {
                  if (snapshotValue.hasOwnProperty(onlineSpeakerExternalId)) {
                    const onlineSpeaker = snapshotValue[onlineSpeakerExternalId]

                    speakersRolesCounters[onlineSpeaker.role].counter++
                  }
                }

                if (speakersRolesCounters.hasOwnProperty(currentUser.role)) {
                  const roleObject = speakersRolesCounters[currentUser.role]
                  if (roleObject.counter >= roleObject.limit) {
                    context.commit('setAuthErrorCode', 3)

                    throw new Error('[session/authenticate] Too many speakers with role "' + currentUser.role + '" connected. Limit is ' + roleObject.limit + '.')
                  }
                }
              } else {
                context.commit('setAuthErrorCode', 4)

                throw new Error('[session/authenticate] Unknown user type ' + JSON.stringify(userType) + '.')
              }

              return response
            })
        })
        .then((response) => {
          // Save token auth
          context.commit('setToken', tokenAPI)

          // Save idRoom
          store.commit('room/setId', response.data.data.room.id)

          // Save data user
          store.commit('user/setUpdate', response.data.data)

          // Save data Tokbox
          store.commit('session/setSessionTokbox', {
            token: response.data.data.tokboxToken,
            sessionId: response.data.data.room.tokboxSessionId,
            systemCheckToken: response.data.data.tokboxSystemCheckToken,
            systemCheckSessionId: response.data.data.room.tokboxSystemCheckSessionId
          })
          return response
        })
        .catch((error) => {
          if (import.meta.env.VITE_DEBUG) {
            console.error(error)
          }
        })
    },
    getToken (context, data) {
      return Request
        .get(endpoints.TOKEN_TOKBOX.replace('{{id}}', data.roomId).replace('{{speakerId}}', data.speakerId))
        .then((response) => store.commit('session/setTokenTokbox', response.data.data))
    }
  }
}
