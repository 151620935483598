export default {
  namespaced: true,
  state: {
    spectators: [],
    spectatorsCount: 0,
  },
  mutations: {
    setSpectators (state, spectators) {
      state.spectators = spectators
    },
    setSpectatorsCount (state, spectatorsCount) {
      state.spectatorsCount = spectatorsCount
    },
  },
  actions: {
    updateSpectators ({ commit }, slots) {
      /**
       * @type {Array}
       */
      const slotsKeys = slots ? Object.keys(slots) : []
      const spectatorsCount = slotsKeys.length ? slots[Math.max(...slotsKeys)] : 0

      commit('setSpectators', slots)
      commit('setSpectatorsCount', spectatorsCount)
    },
  }
}
