import Vue from 'vue'
import VueI18n from 'vue-i18n'

export const availableLocale = ['fr', 'en']

Vue.use(VueI18n)

export const i18n = new VueI18n({
  dateTimeFormats: {
    'fr': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }
    },
    'en': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      }
    }
  }
})



const loadedLanguages = []

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return import(`@/locales/${lang}.js`).then(messages => {
    i18n.setLocaleMessage(lang, messages.default[lang])
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}