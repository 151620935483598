export default {
  namespaced: true,
  state: {
    online: [],
    onLive: null,
    currentBroadcastSharing: null,
    speakerRequests: {},
    speakerRequestAuthor: null,
    requestedWithCamera: false,
    requestedWithAudio: false
  },
  getters: {
    isSpeakersStreamsLimitReached: state => {
      return !!(state.onLive && Object.keys(state.onLive).length >= 4)
    },
    hasCurrentBroadcastSharing: state => externalId => {
      return state.onLive &&
      state.currentBroadcastSharing &&
      typeof state.onLive[externalId] !== 'undefined' &&
      typeof state.currentBroadcastSharing.speakerExternalId !== 'undefined' &&
      state.currentBroadcastSharing.speakerExternalId === externalId
        ? state.currentBroadcastSharing.speakerExternalId
        : null
    },
    hasCurrentBroadcastSharingForDocument: state => (externalId, document) => {
      if (!state.currentBroadcastSharing) {
        return false
      }

      const isUserOnLive = state.onLive && typeof state.onLive[externalId] !== 'undefined'
      const currentBroadcastSharingDocId = String(state.currentBroadcastSharing.documentId)
      const oldDocumentId = (currentBroadcastSharingDocId.indexOf('_') !== -1) ? currentBroadcastSharingDocId.substring(0, currentBroadcastSharingDocId.indexOf('_')) : currentBroadcastSharingDocId
      const newDocId = (String(document.id).indexOf('_') !== -1) ? String(document.id).substring(0, String(document.id).indexOf('_')) : String(document.id)
      const isDocumentAlreadyShared = (oldDocumentId === newDocId)

      return isUserOnLive && isDocumentAlreadyShared
    },
    isOnLive: state => externalId => {
      return state.onLive && typeof state.onLive[externalId] !== 'undefined'
    },
    hasSpeakerRequest: state => (externalId, type) => {
      externalId = String(externalId)

      if (typeof type === 'undefined') {
        return Object.keys(state.speakerRequests).indexOf(externalId) > -1
      }

      if (Object.keys(state.speakerRequests).indexOf(externalId) > -1) {
        return state.speakerRequests[externalId].type === type
      }

      return false
    },
    getSpeakerRequestForUser: state => externalId => {
      externalId = String(externalId)
      if (Object.keys(state.speakerRequests).indexOf(externalId) > -1) {
        return state.speakerRequests[externalId]
      }

      return null
    },
    getSpeakerRequestAuthor: state => state.speakerRequestAuthor,
    getSpeakerRequests: state => state.speakerRequests
  },
  mutations: {
    updateOnline (state, data) {
      state.online = data
    },
    updateOnLive (state, data) {
      state.onLive = data
    },
    setCurrentBroadcastSharing (state, data) {
      state.currentBroadcastSharing = data
    },
    resetCurrentBroadcastSharing (state) {
      state.currentBroadcastSharing = null
    },
    updateSpeakerRequests (state, data) {
      state.speakerRequests = data === null ? {} : data
    },
    setSpeakerRequestAuthor (state, user) {
      state.speakerRequestAuthor = user
    },
    setRequestedWithCamera (state, value) {
      state.requestedWithCamera = value
    },
    setRequestedWithAudio (state, value) {
      state.requestedWithAudio = value
    }
  }
}
