export default class BroadcastHandler {
  init (vue) {
    this.$fireBase = vue.$fireBase
    this.$store = vue.$store
    this.$enums = vue.$enums
    this.$toastr = vue.$toastr

    this.vue = vue
  }

  getBroadcastedDocument () {
    return this.$store.state.documents.broadcastedDocument
  }

  stopDocumentSharing () {
    this.$store.commit('tokbox/resetShare')
    this.$store.commit('documents/setCurrentOffset', 0)

    this.$fireBase.removeCurrentBroadcastSharing()
  }

  startDocumentBroadcast (document) {
    this.$fireBase
      .setCurrentBroadcastSharing(this.$store.state.user.externalId, 'document', document.id.toString(), document.position || 1)
    if (document.parentId) {
      this.$fireBase.saveLastDocumentPosition(document.parentId, document.position || 1)
    }
    this.$fireBase.addSpeakerOnLive(this.$store.state.user.externalId)
  }

  updateCurrentDocumentBroadcastPosition (document) {
    this.$fireBase.updateCurrentBroadcastSharing(
      document.id.toString(),
      document.position ? document.position : 1
    )
    this.$fireBase
      .saveLastDocumentPosition(document.parentId, document.position ? document.position : 1)
  }
}
