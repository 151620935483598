export default Object.freeze({
  AUDIENCE: 'audience',
  GRAPHS: 'graphs',
  POLL: 'poll',
  CHAT: 'chat',
  FAVORITE: 'favorite',
  PARTICIPANTS: 'participants',
  TEAM: 'team',
  TEAM_CHAT: 'team-chat',
  PROFILE: 'profile',
  INTERFACE: 'interface',
  ROOM: 'room',
  PRE_CALL: 'precall',
  BANDWIDTH: 'bandwidth',
  DEVICES: 'devices',
  DOWNLOAD: 'download',
  FIRST_CONNECT: 'first-connect',
  BANNED: 'banned'
})
