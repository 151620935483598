import components from 'plugins/declarations/components'
import enums from 'plugins/enums'
import firebase from '@firebase/app'

export default {
  namespaced: true,
  state: {
    access: false,
    tabActive: components.CHAT,
    activeAndPendingMessages: [],
    favoriteMessages: [],
    deleteMessages: []
  },
  getters: {
    access (state) {
      return state.access
    }
  },
  mutations: {
    setTabActive (state, tabActive) {
      state.tabActive = tabActive
    },
    reverseAllMessages (state) {
      state.activeAndPendingMessages.reverse()
      state.favoriteMessages.reverse()
      state.deleteMessages.reverse()
    },
    setActiveAndPendingMessages (state, messages) {
      state.activeAndPendingMessages = messages
    },
    addActiveAndPendingMessage (state, message) {
      state.activeAndPendingMessages.push(message)
    },
    updateActiveAndPendingMessage (state, message) {
      const messageIndex = state.activeAndPendingMessages.findIndex((o) => o.id === message.id)
      if (messageIndex !== -1) {
        state.activeAndPendingMessages.splice(messageIndex, 1, message)
      }
    },
    removeActiveAndPendingMessage (state, message) {
      const messageIndex = state.activeAndPendingMessages.findIndex((o) => o.id === message.id)
      if (messageIndex !== -1) {
        state.activeAndPendingMessages.splice(messageIndex, 1)
      }
    },
    setFavoriteMessages (state, messages) {
      state.favoriteMessages = messages
    },
    addFavoriteMessage (state, message) {
      state.favoriteMessages.push(message)
    },
    updateFavoriteMessage (state, message) {
      const messageIndex = state.favoriteMessages.findIndex((o) => o.id === message.id)
      if (messageIndex !== -1) {
        state.favoriteMessages.splice(messageIndex, 1, message)
      }
    },
    removeFavoriteMessage (state, message) {
      const messageIndex = state.favoriteMessages.findIndex((o) => o.id === message.id)
      if (messageIndex !== -1) {
        state.favoriteMessages.splice(messageIndex, 1)
      }
    },
    setDeleteMessages (state, messages) {
      state.deleteMessages = messages
    },
    addDeleteMessage (state, message) {
      state.deleteMessages.push(message)
    },
    setAccess (state, message) {
      state.access = message
    }
  },
  actions: {
    clearAllMessages ({ commit }) {
      commit('setActiveAndPendingMessages', [])
      commit('setFavoriteMessages', [])
      commit('setDeleteMessages', [])
    },
    reverseAllMessages ({ commit }) {
      commit('reverseAllMessages')
    },
    updateActiveAndPendingMessage ({ commit }, message) {
      commit('updateActiveAndPendingMessage', message)
    },
    addFavoriteMessage ({ commit }, message) {
      commit('addFavoriteMessage', message)
    },
    updateFavoriteMessage ({ commit }, message) {
      commit('updateFavoriteMessage', message)
    },
    removeFavoriteMessage ({ commit }, message) {
      commit('removeFavoriteMessage', message)
    },
    addMessage ({ commit, state }, message) {
      // Freeze message to prevent slow '_traverse' calls made by Vue
      // Object.freeze(message)

      if (message.status === enums.chatMessageStatus.PENDING || message.status === enums.chatMessageStatus.ACTIVE) {
        const newActiveAndPendingMessages = [...state.activeAndPendingMessages]

        if (state.activeAndPendingMessages.length >= import.meta.env.VITE_FIREBASE_LIMIT_LAZY) {
          newActiveAndPendingMessages.splice(0, 1)
        }

        newActiveAndPendingMessages.push(message)

        commit('setActiveAndPendingMessages', newActiveAndPendingMessages)
        if (message.status === enums.chatMessageStatus.ACTIVE && message.isFavorite) {
          commit('addFavoriteMessage', message)
        }
      } else if (message.status === enums.chatMessageStatus.DELETED) {
        commit('addDeleteMessage', message)
      }
    },
    deleteMessage ({ commit }, message) {
      commit('removeActiveAndPendingMessage', message)
      commit('removeFavoriteMessage', message)
      commit('addDeleteMessage', message)
    },
    updateMessage ({ commit }, message) {
      commit('updateActiveAndPendingMessage', message)
      commit('updateFavoriteMessage', message)
    },
    updateAccess ({ rootGetters, commit }, message) {
      firebase.database().ref(`/rooms/${rootGetters['room/externalId']}/presence/spectators`).once('value').then((snapshot) => {
        commit('setAccess', snapshot.numChildren() <= import.meta.env.VITE_SPEC_CHAT_LIMIT)
      })
    }
  }
}
