import endpoints from 'plugins/declarations/endpoints'
import Request from 'class/Request'
import enums from 'plugins/enums'

export default {
  namespaced: true,
  state: {
    status: null
  },
  actions: {
    play (context, data) {
      const date1970 = new Date('1970-01-01 00:00:00')
      const dateActionAt = new Date(date1970.getTime() + (data.actionAt * 1000))
      const formattedActionAt = ('0' + dateActionAt.getHours()).slice(-2) + ':' + ('0' + dateActionAt.getMinutes()).slice(-2) + ':' + ('0' + dateActionAt.getSeconds()).slice(-2)

      return Request
        .post(endpoints.BROADCAST_RECORDING_PLAY.replace('{{id}}', data.roomId), {
          actionAt: formattedActionAt
        })
        .then((response) => {
          this.status = enums.recording.PLAY
          return response
        })
    },
    pause (context, data) {
      const date1970 = new Date('1970-01-01 00:00:00')
      const dateActionAt = new Date(date1970.getTime() + (data.actionAt * 1000))
      const formattedActionAt = ('0' + dateActionAt.getHours()).slice(-2) + ':' + ('0' + dateActionAt.getMinutes()).slice(-2) + ':' + ('0' + dateActionAt.getSeconds()).slice(-2)

      return Request
        .post(endpoints.BROADCAST_RECORDING_PAUSE.replace('{{id}}', data.roomId), {
          actionAt: formattedActionAt
        })
        .then((response) => {
          this.status = enums.recording.PAUSE
          return response
        })
    }
  }
}
