import Request from 'class/Request'
import endpoints from 'declarations/endpoints'
import store from 'plugins/store'

export default {
  namespaced: true,
  state: {
    speakers: []
  },
  getters: {
    getSpeaker: (state, getters) => (externalId) => {
      return state.speakers.find(o => String(o.externalId) === String(externalId))
    },
    getSpeakerFullName: (state, getters) => (externalId) => {
      const speaker = getters.getSpeaker(externalId)
      return speaker ? speaker.firstname + ' ' + speaker.lastname : 'undefined'
    }
  },
  mutations: {
    setSpeakers (state, speakers) {
      state.speakers = speakers
    }
  },
  actions: {
    get (context, roomId) {
      return Request
        .get(endpoints.SPEAKERS.replace('{{id}}', roomId))
        .then((response) => store.commit('team/setSpeakers', response.data.data))
    }
  }
}
