import endpoints from 'plugins/declarations/endpoints'
import Request from 'class/Request'

export default {
  namespaced: true,
  state: {
    url: null,
    publicUrl: null,
    started: false
  },
  mutations: {
    setUrl (state, data) {
      if (data.url) {
        state.url = data.url
      }
    },
    /** FIXME : Clean this
     * EXPLANATIONS
     * Can be null, url is prefered, this one is only (for now) used in studio mode.
     * Fact is when in studio livestream has to send a m3u8 playlist to have broadcast player before itself going live.
     * Public player can't be used before live. This var is used when broadcaster set room live. It is sent to firebase.
     * More explanations ? pyvignau@infopro-digital.com
     * Dirty ? Yes
     * @param state
     * @param data
     */
    setPublicUrl (state, data) {
      if (data.url) {
        state.publicUrl = data.publicUrl
      }
    },
    setStarted (state, data) {
      state.url = data
    }
  },
  actions: {
    get (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_STREAM_URL.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setUrl', response.data.data)
          return response
        })
    },
    getPublic (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_STREAM_PUBLIC_URL.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setPublicUrl', response.data.data)
          return response
        })
    },
    start (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_START.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setStarted', true)
          return response
        })
    },
    stop (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_STOP.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setStarted', false)
          return response
        })
    },
    startTest (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_START_TEST.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setStarted', true)
          return response
        })
    },
    stopTest (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_STOP_TEST.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('setStarted', false)
          return response
        })
    },
    updateLayout (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_LAYOUT.replace('{{id}}', roomId))
        .then((response) => {
          return response
        })
    }
  }
}
