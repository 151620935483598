export default Object.freeze({
  // Auth
  LIVE_AUTHENTICATE: '/authenticate',
  TOKEN_TOKBOX: '/rooms/{{id}}/speakers/{{speakerId}}/token',

  // Room
  ROOM: '/rooms/{{id}}', // GET
  ROOM_RESET: '/rooms/{{id}}/reset', // POST

  // Speakers
  SPEAKERS: '/rooms/{{id}}/speakers', // GET
  SPEAKER: '/rooms/{{roomId}}/speakers/{{id}}', // PATCH

  // Documents
  DOCUMENTS: '/rooms/{{roomId}}/documents', // POST
  DOCUMENTS_REPLAY: '/rooms/{{roomId}}/documents?status=ready&downloadable=true', // POST
  DOCUMENT: '/rooms/{{roomId}}/documents/{{id}}', // GET - PUT - PATCH - DELETE

  // Polls
  POLLS: '/rooms/{{id}}/polls', // GET - POST
  POLLS_ANSWER: '/rooms/{{id}}/pollsAnswer', // GET - POST

  // Broadcast Live / Replay / Recording
  BROADCAST_STREAM_URL: '/rooms/{{id}}/broadcast/stream/url',
  BROADCAST_STREAM_PUBLIC_URL: '/rooms/{{id}}/broadcast/stream/publicUrl',
  BROADCAST_START: '/rooms/{{id}}/broadcast/start',
  BROADCAST_STOP: '/rooms/{{id}}/broadcast/stop',
  BROADCAST_START_TEST: '/rooms/{{id}}/broadcast/start-test',
  BROADCAST_STOP_TEST: '/rooms/{{id}}/broadcast/stop-test',
  BROADCAST_LAYOUT: '/rooms/{{id}}/broadcast/layout',
  BROADCAST_REPLAY_URL: '/rooms/{{id}}/broadcast/replay/url',
  BROADCAST_RECORDING_PLAY: '/rooms/{{id}}/broadcast/recordings/play',
  BROADCAST_RECORDING_PAUSE: '/rooms/{{id}}/broadcast/recordings/pause',

  LOGS: '/rooms/{{id}}/log'
})
