export default {
  namespaced: true,
  state: {
    color: '',
    theme: '',
    wallpaper: null,
    openColLeftConsole: false,
    openColRightConsole: false,
    openColLeftRoom: false,
    openColRightRoom: false
  },
  mutations: {
    setCustomization (state, data) {
      if (data.theme) {
        state.theme = data.theme
      }
      if (data.color) {
        state.color = data.color
      }
      if (data.wallpaper) {
        state.wallpaper = data.wallpaper
      }
    },
    setOpenColLeftConsole (state, open) {
      state.openColLeftConsole = open
    },
    setOpenColRightConsole (state, open) {
      state.openColRightConsole = open
    },
    setOpenColLeftRoom (state, open) {
      state.openColLeftRoom = open
    },
    setOpenColRightRoom (state, open) {
      state.openColRightRoom = open
    }
  }
}
