<script>
import Logs from 'components/logs/Logs'

export default {
  name: 'App',
  components: {
    Logs
  }
}
</script>

<template>
  <div id="App">
    <Logs/>
    <router-view></router-view>
    <div class="firstLoader"
         id="firstLoader">
      <div class="loaderCircularLarge"></div>
    </div>
  </div>
</template>

<style lang="scss" src="./styles/common.scss">
</style>
