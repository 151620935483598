import Request from 'class/Request'
import endpoints from 'plugins/declarations/endpoints'
import poll from 'plugins/declarations/poll'
import { firebaseSingleton } from '../../class/FireBase'

export default {
  namespaced: true,
  state: {
    formAdd: false,
    formUpdate: false,
    formVote: false,
    form: false,
    filter: poll.ALL_POLLS,
    dataSpectator: {
      votePolls: []
    },
    answerPoll: [],
    pollsOptions: {}
  },
  mutations: {
    setAnswerPoll (state, answerPoll) {
      state.answerPoll = answerPoll
    },
    setFormAdd (state, formAdd) {
      state.formAdd = formAdd
    },
    setFormUpdate (state, formUpdate) {
      state.formUpdate = formUpdate
    },
    setFormVote (state, formVote) {
      state.formVote = formVote
    },
    setUpdate (state, data) {
      state.items = data
    },
    setFilter (state, filter) {
      state.filter = filter
    },
    updatePollsOptions (state, { pollId, options }) {
      state.pollsOptions[pollId] = options
    }
  },
  actions: {
    get (context, roomId) {
      return new Promise((resolve, reject) => {
        return Request
          .get(endpoints.POLLS.replace('{{id}}', roomId))
          .then((response) => {
            resolve(response.data)
          })
      })
    },
    getPollAnswer (context, roomId) {
      return new Promise((resolve, reject) => {
        return Request
          .get(endpoints.POLLS_ANSWER.replace('{{id}}', roomId))
          .then((response) => {
            resolve(response.data)
          })
      })
    },
    getPollOptions ({ state, commit }, pollId) {
      return new Promise((resolve, reject) => {
        if (state.pollsOptions[pollId]) {
          return resolve(state.pollsOptions[pollId])
        }

        firebaseSingleton.getAnswersPoll(pollId)
          .then(data => {
            commit('updatePollsOptions', { pollId, options: {
              id: data.id,
              options: data.data()?.answers || []
            }})
            return resolve(state.pollsOptions[pollId])
          })
          .catch((e) => reject(e))
      })
    }
  }
}
