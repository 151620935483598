export default {
  namespaced: true,
  state: {
    errors: [],
    warnings: [],
    infos: []
  },
  mutations: {
    addError (state, msg) {
      console.error(msg)
      state.errors.push(msg)
    },
    addWarning (state, msg) {
      console.log(msg)
      state.warnings.push(msg)
    },
    addInfo (state, msg) {
      console.info(msg)
      state.infos.push(msg)
    },
    removeError (state) {
      state.errors.shift()
    },
    removeWarning (state) {
      state.warnings.shift()
    },
    removeInfo (state) {
      state.infos.shift()
    }
  },
  actions: {
    error ({ commit, state }, msg) {
      if (!state.errors.length || state.errors[state.errors.length - 1] !== msg) {
        commit('addError', msg)
        setTimeout(() => {
          commit('removeError')
        }, Number(import.meta.env.VITE_TIMEOUT_LOGS))
      }
    },
    warning ({ commit, state }, msg) {
      if (!state.warnings.length || state.warnings[state.warnings.length - 1] !== msg) {
        commit('addWarning', msg)
        setTimeout(() => {
          commit('removeWarning')
        }, Number(import.meta.env.VITE_TIMEOUT_LOGS))
      }
    },
    info ({ commit, state }, msg) {
      if (!state.infos.length || state.infos[state.infos.length - 1] !== msg) {
        commit('addInfo', msg)
        setTimeout(() => {
          commit('removeInfo')
        }, Number(import.meta.env.VITE_TIMEOUT_LOGS))
      }
    }
  }
}
