import { v4 as uuidv4 } from 'uuid'

export default {
  namespaced: true,
  state: {
    chatTeam: 0,
    chatPublic: 0,
    download: 0,
    newPoll: 0,
    speakerNotificationPending: 0,
    newNotifications: []
  },
  getters: {
    newNotifications (state) {
      return Object.values(state.newNotifications.filter(n => !n.hiddenByUser))
    }
  },
  mutations: {
    setChatTeam (state, count) {
      state.chatTeam = count
    },
    incrementChatPublic (state) {
      state.chatPublic++
    },
    decrementChatTeam (state, count) {
      state.chatTeam -= count

      if (state.chatTeam < 0) {
        state.chatTeam = 0
      }
    },
    resetChatTeam (state) {
      state.chatTeam = 0
    },
    resetChatPublic (state) {
      state.chatPublic = 0
    },
    setDownload (state, count) {
      state.download = count
    },
    incrementDownload (state) {
      state.download++
    },
    resetDownload (state) {
      state.download = 0
    },
    incrementSpeakerNotificationPending (state) {
      state.speakerNotificationPending++
    },
    resetSpeakerNotificationPending (state) {
      state.speakerNotificationPending = 0
    },
    incrementNewPoll (state) {
      state.newPoll++
    },
    resetNewPoll (state) {
      state.newPoll = 0
    },
    addNewNotification (state, { id, title, content, minimumTime, force, allowClose }) {
      // options { id?: string, title?: string, content: string, minimumTime?: number, allowClose: boolean }
      const notifExist = state.newNotifications.find(n => n.id === id)
      if (id && notifExist) {
        if (!force) return false
        notifExist.hiddenByUser = false
        notifExist.createdAt = Date.now()
        return
      } else if (!id) id = uuidv4()

      state.newNotifications.push({
        id,
        title,
        content,
        minimumTime,
        createdAt: Date.now(),
        hiddenByUser: false,
        timeout: null,
        allowClose
      })
    },
    removeNewNotification (state, { id, timeout }) {
      const notificationIndex = state.newNotifications.findIndex(n => n.id === id)
      if (notificationIndex >= 0) { state.newNotifications.splice(notificationIndex, 1) }
    },
    hideNewNotification (state, id) {
      const notification = state.newNotifications.find(n => n.id === id)
      if (notification) {
        notification.hiddenByUser = true
      }
    }
  },
  actions: {
    incrementChatPublic ({ commit }, count) {
      commit('incrementChatPublic', count)
    },
    resetChatPublic ({ commit }) {
      commit('resetChatPublic')
    },
    createConnectionIssueNotification ({ dispatch }) {
      dispatch('addNewNotification', {
        id: 'connectionIssue',
        title: 'notification.connectionDrop.title',
        content: 'notification.connectionDrop.content',
        minimumTime: 5000
      })
    },
    removeConnectionIssueNotification ({ dispatch }, { force = false, hiddenByUser = false } = {}) {
      dispatch('removeNewNotification', { id: 'connectionIssue', force, hiddenByUser })
    },
    addNewNotification ({ commit }, { id, title, content, minimumTime = 0, force = false, notifToRemove = [], allowClose = true }) {
      if (notifToRemove) {
        notifToRemove = Array.isArray(notifToRemove) ? notifToRemove : [notifToRemove]
        for (const notifId of notifToRemove) {
          commit('removeNewNotification', { id: notifId })
        }
      }
      commit('addNewNotification', { id, title, content, minimumTime, force, allowClose })
    },
    removeNewNotification ({ commit, state }, { id, force = false, hiddenByUser = false }) {
      const notification = state.newNotifications.find(n => n.id === id)
      if (hiddenByUser) {
        commit('hideNewNotification', id)
        return true
      }
      if (!notification || notification.timeout !== null) return false // Already waiting timeout to remove
      const timeout = force ? 0 : notification.minimumTime - (Date.now() - notification.createdAt)
      state.newNotifications.timeout = setTimeout(function () {
        commit('removeNewNotification', { id, timeout })
      }, timeout < 0 ? 0 : timeout)
    }
  }
}
