<script>
export default {
  name: 'Logs',
  computed: {
    hasError () {
      return this.$store.state.log.errors.length
    },
    hasWarning () {
      return this.$store.state.log.warnings.length
    },
    hasInfo () {
      return this.$store.state.log.infos.length
    }
  },
  watch: {
    hasError (newVal, oldVal) {
    },
    hasWarning (newVal, oldVal) {
    },
    hasInfo (newVal, oldVal) {
    }
  }
}
</script>

<template>
  <div>
    <ul v-if="hasError"
        class="log log--error">
      <li v-for="(message, index) in $store.state.log.errors"
          :key="index">
        {{message}}
      </li>
    </ul>
    <ul v-if="hasWarning"
        class="log log--warning">
      <li v-for="(message, index) in $store.state.log.warnings"
          :key="index">
        {{message}}
      </li>
    </ul>
    <ul v-if="hasInfo"
        class="log log--info">
      <li v-for="(message, index) in $store.state.log.infos"
          :key="index">
        {{message}}
      </li>
    </ul>
  </div>
</template>

<style scoped lang="scss">
  @import '~styles/lib/_variables';

  .log {
    margin: 0;
    padding: 10px 30px;
    position: fixed;
    z-index: 9999;
    top: $headerHeight + 30;
    left: 50%;
    list-style: none;
    border-radius: 4px;
    border: 1px solid Black;
    background-color: Black;
    box-shadow: 0 0 6px rgba(Black,.3);
    color: White;
    text-align: center;
    font-weight: bold;
    transform: translateX(-50%);

    &--error {
      border-color: darken($colorError, 20%);
      background-color: $colorError;
    }

    &--warning {
      border-color: darken($colorWarning, 20%);
      background-color: $colorWarning;
      color: darken($colorWarning, 40%);
    }

    &--info {
      border-color: darken($colorInfo, 20%);
      background-color: $colorInfo;
      color: darken($colorInfo, 40%);
    }
  }
</style>
