import Request from 'class/Request'
import endpoints from 'plugins/declarations/endpoints'

export default {
  namespaced: true,
  state: {
    items: [],
    openMenu: false,
    carouselWidth: 0,
    carouselContainerWidth: 0,
    broadcastedDocument: {
      documentId: null,
      subDocumentPosition: null,
      externalSpeakerId: null
    },
    currentOffset: 0,
    documentAtLastPosition: null
  },
  getters: {
    downloadableDocuments: state => () => {
      return state.items.filter(item => item.downloadable)
    },
    getDocumentById: state => (id) => {
      return state.items.find(item => {
        return item.id === id
      })
    },
    getDocumentAtLastPosition: state => () => {
      return state.documentAtLastPosition
    },
    getBroadcastedDocument: state => {
      return state.broadcastedDocument
    }
  },
  mutations: {
    setItems (state, documents) {
      state.items = documents
    },
    addItems (state, document) {
      state.items.push(document)
    },
    deleteItem (state, id) {
      for (let i in state.items) {
        if (state.items[i].id === id) {
          state.items.splice(i, 1)
          break
        }
      }
    },
    setDocumentAtLastPosition (state, documentAtLastPosition) {
      state.documentAtLastPosition = documentAtLastPosition
    },
    setOpenMenu (state, openMenu) {
      state.openMenu = openMenu
    },
    updateListDocuments (state, { key, value }) {
      state.items[key] = value
    },
    setCarouselWidth (state, carouselWidth) {
      state.carouselWidth = carouselWidth
    },
    setCarouselContainerWidth (state, carouselContainerWidth) {
      state.carouselContainerWidth = carouselContainerWidth
    },
    setBroadcastedDocument (state, document) {
      state.broadcastedDocument = document
    },
    setCurrentOffset (state, offset) {
      state.currentOffset = Number.parseInt(offset, 10)
    }
  },
  actions: {
    setDocuments (context, documents) {
      context.commit('setItems', documents)
    },
    getListDocuments (context, roomId) {
      return Request
        .get(endpoints.DOCUMENTS.replace('{{roomId}}', roomId))
        .then((response) => context.commit('setItems', response.data.data))
    },
    getListDocumentsReplay (context, roomId) {
      return Request
        .get(endpoints.DOCUMENTS_REPLAY.replace('{{roomId}}', roomId))
        .then((response) => context.commit('setItems', response.data.data))
    },
    postDocument (context, data) {
      const config = Object.assign({}, data.config)
      return new Promise((resolve, reject) => {
        return Request
          .post(endpoints.DOCUMENTS.replace('{{roomId}}', data.roomId), data.formData, config)
          .then((response) => {
            resolve(response.data.data)
          }).catch((err) => {
            reject(err)
          })
      })
    },
    getDocument (context, data) {
      return new Promise((resolve, reject) => {
        return Request
          .get(endpoints.DOCUMENT.replace('{{roomId}}', data.roomId).replace('{{id}}', data.id))
          .then((response) => {
            resolve(response.data.data)
          })
      })
    },
    patchDocument (context, data) {
      data['_method'] = 'patch'
      return new Promise((resolve, reject) => {
        return Request
          .post(endpoints.DOCUMENT.replace('{{roomId}}', data.roomId).replace('{{id}}', data.id), data)
          .then((response) => {
            resolve(response.data.data)
          })
      })
    },
    updateDocument ({ commit, state }, data) { // Update document if exists, adds it instead
      if (data.id) {
        for (let i in state.items) {
          if (state.items[i].id === data.id) {
            return commit('updateListDocuments', { key: i, value: data })
          }
        }
        return commit('addItems', data)
      }
    },
    deleteDocument (context, data) {
      data['_method'] = 'delete'

      return new Promise((resolve, reject) => {
        return Request
          .post(endpoints.DOCUMENT.replace('{{roomId}}', data.roomId).replace('{{id}}', data.id), data)
          .then((response) => {
            context.commit('deleteItem', data.id)
            resolve(response)
          })
      })
    }
  }
}
