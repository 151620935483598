import checkStatus from 'plugins/declarations/checkStatus'

export default {
  namespaced: true,
  state: {
    status: checkStatus.IN_PROGRESS,
    connectionToApiServer: false,
    audioQuality: {},
    videoQuality: {}
  },
  mutations: {
    setStatus (state, status) {
      state.status = status
    },
    setConnectionToApiServer (state, connectionToApiServer) {
      state.connectionToApiServer = connectionToApiServer
    },
    setVideoQuality (state, videoQuality) {
      state.videoQuality = videoQuality
    },
    setAudioQuality (state, audioQuality) {
      state.audioQuality = audioQuality
    }
  }
}
