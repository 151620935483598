import actions from 'declarations/actions'
import blacklist from 'declarations/blacklist'
import browser from 'declarations/browser'
import chatMessageStatus from 'declarations/chatMessageStatus'
import chatTypes from 'declarations/chatTypes'
import checkStatus from 'declarations/checkStatus'
import components from 'declarations/components'
import documents from 'declarations/documents'
import firebase from 'declarations/firebase'
import graphs from 'declarations/graphs'
import matchMedia from 'declarations/matchMedia'
import math from 'declarations/math'
import pages from 'declarations/pages'
import poll from 'declarations/poll'
import recording from 'declarations/recording'
import regex from 'declarations/regex'
import roles from 'declarations/roles'
import roomStatus from 'declarations/roomStatus'
import roomBackupStatus from 'declarations/roomBackupStatus'
import status from 'declarations/status'
import tokbox from 'declarations/tokbox'
import broadcastType from 'declarations/broadcastType'
import broadcastProvider from 'declarations/broadcastProvider'
import roomBroadcastProvider from 'declarations/roomBroadcastProvider'

export default Object.freeze({
  actions,
  blacklist,
  browser,
  chatMessageStatus,
  chatTypes,
  checkStatus,
  components,
  documents,
  firebase,
  graphs,
  matchMedia,
  math,
  pages,
  poll,
  recording,
  regex,
  roles,
  roomStatus,
  roomBackupStatus,
  roomBroadcastProvider,
  status,
  tokbox,
  broadcastType,
  broadcastProvider
})
