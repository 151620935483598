export default {
  namespaced: true,
  state: {
    scoreHistory: [],
    reasons: [],
    showStats: false
  },
  getters: {
    getAverageScore (state) {
      if (!state.scoreHistory.length) return 0
      return Math.round(state.scoreHistory.reduce((a, b) => a + b, 0) / state.scoreHistory.length)
    },
    reasons (state) {
      return state.reasons
    },
    showStats (state) {
      return state.showStats
    }
  },
  mutations: {
    addScoreToHistory (state, score) {
      if (state.scoreHistory.length >= 5) state.scoreHistory.shift()
      state.scoreHistory.push(score)
    },
    clearHistory (state) {
      state.scoreHistory = []
    },
    addReasons (state, reasons) {
      state.reasons = reasons
    },
    setShowStats (state, value) {
      state.showStats = value
    }
  },
  actions: {
    addScoreToHistory (context, score) {
      context.commit('addScoreToHistory', score)
    },
    clearHistory (context) {
      context.commit('clearHistory')
    },
    addReasons (context) {
      context.commit('addReasons')
    },
    showStats (context) {
      context.commit('setShowStats', true)
    },
    toggleStats ({ commit, state }) {
      commit('setShowStats', !state.showStats)
    }
  }
}
