import Vue from 'vue'
import VueRouter from 'vue-router'
import enums from 'plugins/enums'
import store from 'plugins/store'

Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/' + enums.pages.CONSOLE,
      name: enums.pages.CONSOLE,
      component: () => import('pages/Console'),
      beforeEnter (to, from, next) {
        /*
          QUICKWIN : Lazy load tokbox vue plugin on Console page to avoid ie11 issues for spectators.
          OpenTok.js version 2.16 is the last version to support the OpenTok Plugin for Internet Explorer.
          OpenTok.js version 2.16 will be deprecated in May 2020 for the Standard line
          and June 2020 for the Enterprise line.
          TODO :
           * Remove this hack
           * Stop using tokbox instance in vue components from vue prototype
           * Create clean vuex opentok actions instead
         */
        import('plugins/tokbox').then(({ default: _ }) => {
          Vue.prototype.$tokbox = _
          if (store.state.session.token) {
            store.commit('session/setToken', store.state.session.token) // persist token
            store
              .dispatch('room/get')
              .then(next)
              .catch(() => next(enums.pages.ERROR_404))
          } else {
            next(enums.pages.ERROR_404)
          }
        })
          .catch(console.error)
      }
    },
    {
      path: '/' + enums.pages.SYSTEM_CHECK + '/:token',
      name: enums.pages.SYSTEM_CHECK,
      component: () => import('pages/SystemCheck'),
      beforeEnter (to, from, next) {
        if (to.params.token) {
          sessionStorage.setItem('memory-token', to.params.token)
        }
        store
          .dispatch('session/authenticate', to)
          .then(response => {
            const apiData = response.data.data

            // Status room
            switch (apiData.room.status) {
              case enums.roomStatus.DRAFT:
              case enums.roomStatus.LIVE:
                // Role user
                switch (apiData.role) {
                  case enums.roles.ADMIN:
                  case enums.roles.MANAGER:
                  case enums.roles.SPEAKER:
                  case enums.roles.MODERATOR:
                    next()
                    break
                  default:
                    next(enums.pages.ERROR_404)
                }
                break
              case enums.roomStatus.REPLAY:
                next('/' + enums.pages.REPLAY)
                break
              default:
                next(enums.pages.ERROR_404)
            }
          })
          .catch(() => next(enums.pages.ERROR_404))
      }
    },
    {
      path: '/' + enums.pages.LIVE,
      name: enums.pages.LIVE,
      component: () => import('pages/Live'),
      beforeEnter (to, from, next) {
        if (store.state.session.token) {
          store.commit('session/setToken', store.state.session.token) // persist token
          store
            .dispatch('room/get')
            .then(next)
            .catch(() => next(enums.pages.ERROR_404))
        } else {
          next(enums.pages.ERROR_404)
        }
      }
    },
    {
      path: '/' + enums.pages.REPLAY,
      name: enums.pages.REPLAY,
      component: () => import('pages/Replay'),
      beforeEnter (to, from, next) {
        if (store.state.session.token) {
          store.commit('session/setToken', store.state.session.token) // persist token
          store
            .dispatch('room/get')
            .then(next)
            .catch(() => next(enums.pages.ERROR_404))
        } else {
          next(enums.pages.ERROR_404)
        }
      }
    },
    {
      path: '/' + enums.pages.ERROR + '/:code',
      name: enums.pages.ERROR,
      component: () => import('pages/Error')
    },
    {
      path: '/' + enums.pages.PREVIEW,
      name: enums.pages.PREVIEW,
      query: { theme: '', color: '', image: '', preLiveImage: '' },
      component: () => import('pages/Preview')
    },
    {
      path: '/:token',
      beforeEnter (to, from, next) {
        if (to.params.token) {
          sessionStorage.setItem('memory-token', to.params.token)
        }
        store
          .dispatch('session/authenticate', to)
          .then(response => {
            const apiData = response.data.data

            // Status room
            switch (apiData.room.status) {
              case enums.roomStatus.DRAFT:
              case enums.roomStatus.LIVE:
                // Role user
                switch (apiData.role) {
                  case enums.roles.ADMIN:
                  case enums.roles.MANAGER:
                  case enums.roles.SPEAKER:
                  case enums.roles.MODERATOR:
                    next('/' + enums.pages.CONSOLE)
                    break
                  case enums.roles.SPECTATOR:
                    next('/' + enums.pages.LIVE)
                    break
                  default:
                    next(enums.pages.ERROR_404)
                }
                break
              case enums.roomStatus.REPLAY:
                next('/' + enums.pages.REPLAY)
                break
              default:
                next(enums.pages.ERROR_404)
            }
          })
          .catch(() => next(enums.pages.ERROR_403))
      }
    },
    {
      path: '*',
      redirect: enums.pages.ERROR_404
    }
  ]
})

// Check for IE
router.beforeEach((to, from, next) => {
  if (to.query.isIE === '1' || from.query.isIE === '1') {
    store.commit('checkIsIE', to.query.isIE === '1' || from.query.isIE === '1')
  } else {
    store.commit('checkIsIE')
  }
  next()
})

export default router
