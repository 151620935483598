import { i18n } from 'plugins/i18n'
import endpoints from 'plugins/declarations/endpoints'
import components from 'plugins/declarations/components'
import Request from 'class/Request'
import store from 'plugins/store'
import roomBackupStatus from 'declarations/roomBackupStatus'
import enums from 'plugins/enums'

export default {
  namespaced: true,
  state: {
    id: null,
    externalId: null,
    title: '',
    description: '',
    socialNetworkingUrl: null,
    duration: 0,
    status: null,
    startDate: null,
    customization: null,
    date: '',
    backupStatus: roomBackupStatus.PENDING,
    firstConnect: !localStorage.getItem(components.FIRST_CONNECT),
    delayBeforeLive: 15,
    lang: i18n.locale,
    broadcastType: null,
    broadcastStudioConfiguration: null,
    broadcastProvider: null,
    ivsConfig: null,
    hotlineUrl: null
  },
  mutations: {
    setId (state, id) {
      state.id = id
    },
    setLayout (state, firstConnect) {
      state.firstConnect = firstConnect
    },
    setLang (state, lang) {
      state.lang = lang
    },
    setUpdate (state, data) {
      if (!data) {
        return
      }

      if (data.id) {
        state.id = data.id
      }
      if (data.externalId) {
        state.externalId = data.externalId
      }
      if (data.title) {
        state.title = data.title
      }
      if (data.description) {
        state.description = data.description
      }
      if (data.duration) {
        state.duration = data.duration
      }
      if (data.status) {
        state.status = data.status
      }
      if (data.startDate) {
        state.startDate = data.startDate
      }
      if (data.socialNetworkingUrl) {
        state.socialNetworkingUrl = data.socialNetworkingUrl
      }
      if (data.customization) {
        state.customization = data.customization
      }
      if (data.backupStatus) {
        state.backupStatus = data.backupStatus
      }
      if (data.broadcastType) {
        state.broadcastType = data.broadcastType
      }
      if (data.broadcastStudioConfiguration) {
        state.broadcastStudioConfiguration = JSON.parse(data.broadcastStudioConfiguration)
      }
      if (data.broadcastProvider) {
        state.broadcastProvider = data.broadcastProvider
      }
      if (data.ivsConfig) {
        state.ivsConfig = data.ivsConfig
      }
      if (data.hotlineUrl) {
        state.hotlineUrl = data.hotlineUrl
      }
    },
    resetIvsConfig (state) {
      state.ivsConfig = {}
    }
  },
  getters: {
    isStudio (state) {
      return state.broadcastType === 'studio'
    },
    isIVS (state) {
      return state.broadcastProvider === 'IVS'
    },
    hasChat (state) {
      if (state.customization && state.customization.chatType) {
        return (state.customization.chatType !== enums.chatTypes.NONE)
      }
      return true
    },
    customizationChatType (state) {
      if (state.customization && state.customization.chatType) {
        return state.customization.chatType
      }
      return enums.chatTypes.NONE
    },
    externalId (state) {
      return state.externalId
    },
    provider (state) {
      return state.broadcastProvider
    }
  },
  actions: {
    get (context) {
      return Request
        .get(endpoints.ROOM.replace('{{id}}', context.state.id))
        .then((response) => {
          // Save data Room
          context.commit('setUpdate', response.data.data)
          // Save customization room
          if (response.data.data.customization) {
            store.commit('parameters/setCustomization', response.data.data.customization)
          }
          // Save Speakers
          if (response.data.data.speakers) {
            store.commit('team/setSpeakers', response.data.data.speakers)
          }

          return response
        })
    },
    patch (context, data) {
      data['_method'] = 'patch'

      return Request
        .post(endpoints.ROOM.replace('{{id}}', context.state.id), data)
        .then((response) => {
          context.commit('setUpdate', response.data.data)
        })
    },
    reset (context, data) {
      return Request
        .post(endpoints.ROOM_RESET.replace('{{id}}', context.state.id), data)
    }
  }
}
