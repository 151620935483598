export default {
  namespaced: true,
  state: {
    camera: false,
    audio: false,
    image: false,
    video: false,
    screen: false,
    deviceVideo: null,
    deviceAudio: null,
    modalConfirmShare: false,
    documentShare: null,
    muteAll: false,
    openDocuments: false
  },
  mutations: {
    setCamera (state, camera) {
      state.camera = camera
    },
    setAudio (state, audio) {
      state.audio = audio
    },
    resetCameraAudio (state) {
      state.camera = false
      state.audio = false
    },
    setImage (state, image) {
      state.image = image
      if (image) {
        state.video = false
        state.screen = false
      }
    },
    setVideo (state, video) {
      state.video = video
      if (video) {
        state.image = false
        state.screen = false
      }
    },
    setScreen (state, screen) {
      state.screen = screen
      if (screen) {
        state.image = false
        state.video = false
      }
    },
    resetShare (state) {
      state.screen = false
      state.image = false
      state.video = false
      state.documentShare = null
    },
    resetDocumentShare (state) {
      state.image = false
      state.video = false
      state.documentShare = null
    },
    setDeviceVideo (state, deviceVideo) {
      state.deviceVideo = deviceVideo
    },
    setDeviceAudio (state, deviceAudio) {
      state.deviceAudio = deviceAudio
    },
    setModalConfirmShare (state, modalConfirmShare) {
      state.modalConfirmShare = modalConfirmShare
    },
    setDocumentShare (state, documentShare) {
      state.documentShare = documentShare
    },
    setMuteAll (state, mute) {
      state.muteAll = mute
    },
    setOpenDocuments (state, openDocuments) {
      state.openDocuments = openDocuments
    }
  },
  getters: {
    getImage (state) {
      return state.image
    },
    getVideo (state) {
      return state.video
    },
    getScreen (state) {
      return state.screen
    }
  }
}
