import endpoints from 'plugins/declarations/endpoints'
import roles from 'plugins/declarations/roles'
import Request from 'class/Request'

export default {
  namespaced: true,
  state: {
    id: null,
    fact: null, // firebase auth custom token :)
    externalId: null,
    role: null,
    firstname: '',
    lastname: '',
    email: '',
    function: null,
    description: '',
    company: null,
    avatar: null
  },
  getters: {
    isAdminOrManagerOrModerator: state => {
      return state.role === roles.ADMIN || state.role === roles.MANAGER || state.role === roles.MODERATOR
    },
    isAdminOrManager: state => {
      return state.role === roles.ADMIN || state.role === roles.MANAGER
    },
    isAdmin: state => {
      return state.role === roles.ADMIN
    },
    isManager: state => {
      return state.role === roles.MANAGER
    },
    isSpeaker: state => {
      return state.role === roles.SPEAKER
    },
    isModerator: state => {
      return state.role === roles.MODERATOR
    },
    isSpectator: state => {
      return state.role === roles.SPECTATOR
    },
    fullname: state => {
      return state.firstname + ' ' + state.lastname
    },
    externalId: state => {
      return state.externalId
    }
  },
  mutations: {
    setUpdate (state, data) {
      if (data.externalId) {
        state.externalId = data.externalId
      }
      if (data.id) {
        state.id = data.id
      }
      if (data.fact) {
        state.fact = data.fact
      }
      if (data.role) {
        state.role = data.role
      }
      if (data.firstname) {
        state.firstname = data.firstname
      }
      if (data.lastname) {
        state.lastname = data.lastname
      }
      if (data.email) {
        state.email = data.email
      }
      if (data.function) {
        state.function = data.function
      }
      if (data.description) {
        state.description = data.description
      }
      if (data.profile && data.profile.url) {
        state.avatar = data.profile.url
      }
      if (data.company) {
        state.company = data.company
      }
    },
    setAvatar (state, avatar) {
      state.avatar = avatar
    }
  },
  actions: {
    saveUser (context, { roomId, newAvatarFile }) {
      const formData = new FormData()
      formData.append('_method', 'patch')
      formData.append('id', context.state.id)
      formData.append('externalId', context.state.externalId)
      formData.append('role', context.state.role)
      formData.append('firstname', context.state.firstname)
      formData.append('lastname', context.state.lastname)
      formData.append('email', context.state.email)
      formData.append('function', context.state.function)
      formData.append('description', context.state.description)
      if (newAvatarFile) {
        formData.append('profile', newAvatarFile)
      }
      return Request
        .post(endpoints.SPEAKER.replace('{{roomId}}', roomId).replace('{{id}}', context.state.id), formData)
        .then((response) => context.commit('setUpdate', response.data.data))
    }
  }
}
