import firebase from '@firebase/app'
import '@firebase/firestore'
import '@firebase/database'
import '@firebase/auth'

import poll from 'plugins/declarations/poll'
import roles from 'plugins/declarations/roles'
import actions from 'plugins/declarations/actions'
import firebaseChannel from 'plugins/declarations/firebase'

export default class FireBase {
  /**
   * Constructor
   */
  constructor () {
    firebase.initializeApp({
      apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
      authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
      projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
      databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL
    })

    this.database = firebase.database()
    this.service = firebase.firestore()
    this.auth = firebase.auth()

    this.deltaTimestamp = 0
    this.room = null
    this.user = null

    this.database
      .ref('.info/serverTimeOffset')
      .on('value', (snap) => (this.deltaTimestamp = snap.val()))
  }

  /**
   * Set ROOM
   *
   * @param room
   */
  setRoom (room) {
    this.room = room
  }

  /**
   * set User current (me)
   *
   * @param user
   */
  setUser (user) {
    this.user = user
  }

  /**
   * Get timestamp with delta server
   *
   * @returns {Number|*}
   */
  getTimestamp () {
    return Date.now() + this.deltaTimestamp
  }

  /**
   * Get collection reference
   *
   * @param collectionName
   * @returns {CollectionReference}
   */
  getCollection (collectionName) {
    return this.service
      .collection(collectionName)
  }

  /**
   * authenticate firebase user
   *
   * @param customToken
   */
  authenticate (customToken) {
    return this.auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return this.auth.signInWithCustomToken(customToken)
          .catch((error) => {
            throw new Error('[firebase/authenticate] signInWithCustomToken failed. code:' + error.code + ', message: ' + error.message)
          })
      })
  }

  /**
   * Logout the current user
   * @returns {!firebase.Promise<void>|Promise<void>}
   */
  unauthenticate () {
    const type = this.user.role === roles.SPECTATOR ? 'spectators' : 'speakers'

    if (this.user.role !== roles.SPECTATOR) {
      this.database
        .ref('/rooms/' + this.room.externalId + '/console/isLive/' + this.user.externalId)
        .remove()

      this.database
        .ref('/rooms/' + this.room.externalId + '/console/speakerRequests/' + this.user.externalId)
        .remove()
    }

    return this.database
      .ref('/rooms/' + this.room.externalId + '/presence/' + type + '/' + this.user.externalId)
      .remove().then(() => {
        return this.auth.signOut()
      })
  }

  /**
   * Set watcher on /broadcastUrl room root var, useful for getting live url on spectator side
   * @returns {*}
   */
  watchBroadcastUrl () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/broadcastUrl')
  }

  /**
   * Return tokboxBroadcastUrl ref
   * @returns {*}
   */
  watchTokboxBroadcastUrl () {
    return this.database
      .ref(`/rooms/${this.room.externalId}/tokboxBroadcastUrl`)
  }

  watchIvsBroadcastUrl () {
    return this.database
      .ref(`/rooms/${this.room.externalId}/ivsBroadcastUrl`)
  }

  /**
   * Set broadcasting url for current room
   * @param url string
   * @returns {*}
   */
  setBroadcastUrl (url) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/broadcastUrl')
      .set(url)
  }

  /**
   * Send a message
   * @param {string} channelName
   * @param {string} message
   * @param {string} status
   */
  addMessageOnChat (channelName, message, status) {
    if (this.room.externalId && this.user.externalId) {
      const data = {
        message,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        roomExternalId: this.room.externalId,
        userFromExternalId: this.user.externalId,
        currentUserFirstName: this.user.firstname,
        currentUserLastName: this.user.lastname,
        isFavorite: false,
        status
      }

      return this.getCollection(channelName)
        .add(data)
    }

    return Promise.reject(new Error('Add room externalId and/or user externalId'))
  }

  /**
   * Watcher message
   * @param {string} channelName
   * @param {array} statuses
   * @returns {Query}
   *
   * onSnapshot(querySnapshot => {
   *    querySnapshot
   *      .forEach(message => {
   *          console.log(message.data())
   *      })
   *  })
   */
  watchMessageOnChat (channelName, statuses) {
    return this.getCollection(channelName)
      .where('roomExternalId', '==', this.room.externalId)
      .where('status', 'in', statuses)
      .orderBy('createdAt', 'desc')
      .limit(1)
  }

  /**
   * Watcher message
   * @param {string} channelName
   * @param {array} statuses
   * @returns {Query}
   *
   * onSnapshot(querySnapshot => {
   *    querySnapshot
   *      .forEach(message => {
   *          console.log(message.data())
   *      })
   *  })
   */
  watchLastUpdatedMessageOnChat (channelName, statuses) {
    return this.getCollection(channelName)
      .where('roomExternalId', '==', this.room.externalId)
      .where('status', 'in', statuses)
      .orderBy('updatedAt', 'desc')
      .limit(1)
  }

  /**
   * Prepare messages query
   * @param {string} channelName
   * @param {array} statuses
   */
  getMessagesOnChat (channelName, statuses) {
    return this.getCollection(channelName)
      .where('roomExternalId', '==', this.room.externalId)
      .where('status', 'in', statuses)
      .orderBy('createdAt', 'desc')
      .limit(Number(import.meta.env.VITE_FIREBASE_LIMIT_HISTORY))
      .get()
  }

  /**
   * @param {string} channelName
   */
  async emptyPublicChat (channelName) {
    if (this.room.externalId) {
      const querySnapshot = await this.getCollection(channelName)
        .where('roomExternalId', '==', this.room.externalId)
        .get()
      const batch = this.service.batch()
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref)
      })
      return batch.commit()
    }

    return Promise.reject(new Error('The emptying of the public chat has failed'))
  }

  /**
   * Add a new pool with answers
   * @param formPoll
   * @param formAnswers
   * @returns {*}
   */
  addNewPoll (formPoll, formAnswers) {
    if (this.room.externalId && this.user.externalId) {
      if (formPoll.whiteVote) {
        formAnswers.answers.push({
          label: 'Vote blanc',
          count: 0,
          average: 0,
          isWhiteVote: true
        })
      }
      return this.getCollection(firebaseChannel.POLL_CHANNEL)
        .add({
          ...formPoll,
          roomExternalId: this.room.externalId,
          userOwnerExternalId: this.user.externalId,
          currentUserFirstName: this.user.firstname,
          currentUserLastName: this.user.lastname,
          avatar: this.user.avatar,
          statePoll: poll.PRE_POLLS,
          timeStart: null,
          openVote: false,
          stopVote: false,
          propagation: formPoll.autoResult,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(doc => this.getCollection(firebaseChannel.POLL_ANSWER_CHANNEL)
          .add({
            ...formAnswers,
            pollId: doc.id,
            roomExternalId: this.room.externalId,
            userOwnerExternalId: this.user.externalId,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }))
    }

    return Promise.reject(new Error('Add room externalId and/or user externalId'))
  }

  /**
   * Poll watcher
   *
   * @returns {Query}
   */
  watchPoll () {
    return this.getCollection(firebaseChannel.POLL_CHANNEL)
      .where('roomExternalId', '==', this.room.externalId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
  }

  /**
   * Prepare messages query
   * @param {number} length
   */
  getPolls (length = Number(import.meta.env.VITE_FIREBASE_LIMIT_HISTORY)) {
    return this.getCollection(firebaseChannel.POLL_CHANNEL)
      .where('roomExternalId', '==', this.room.externalId)
      .orderBy('updatedAt', 'desc')
      .limit(length)
      .get()
  }

  /**
   * Update poll by doc ID
   * @param pollId
   * @param formPoll
   * @param formAnswers
   * @returns {Promise<void>}
   */
  updatePollByDocId (pollId, formPoll, formAnswers = null) {
    const ref = this.getCollection(firebaseChannel.POLL_CHANNEL)
      .doc(pollId)
      .update({
        ...formPoll,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })

    if (formAnswers) {
      // Remove whiteVote
      let anwsers = formAnswers.filter(answer => !answer.isWhiteVote)

      if (formPoll.whiteVote) {
        anwsers.push({
          label: 'Vote blanc',
          count: 0,
          average: 0,
          isWhiteVote: true
        })
      }

      formAnswers.answers = anwsers

      return ref
        .then(() => {
          return this.getAnswersPoll(pollId)
            .then(doc => this.getCollection(firebaseChannel.POLL_ANSWER_CHANNEL)
              .doc(doc.id)
              .update({
                ...formAnswers,
                updatedAt: firebase.firestore.FieldValue.serverTimestamp()
              }))
        })
    }

    return ref
  }

  /**
   * Delete poll by doc ID
   * @param docId
   * @returns {Promise<void>}
   */
  deletePollByDocId (docId) {
    return this.updatePollByDocId(docId, {
      statePoll: poll.CANCEL_POLLS
    })
  }

  /**
   * Open poll by doc ID
   * @param docId
   * @param formPoll
   * @param formAnswers
   * @returns {Promise<void>}
   */
  openPollByDocId (docId, formPoll, formAnswers) {
    let dataAnswers = null
    const dataPool = {
      timeStart: formPoll.timer ? firebase.firestore.FieldValue.serverTimestamp() : null,
      statePoll: poll.OPEN_POLLS
    }

    if (formPoll.timer) {
      setTimeout(() => this.closePollByDocId(docId), formPoll.lapsAnswer * 60 * 1000)
    }
    return this.updatePollByDocId(docId, dataPool, dataAnswers)
  }

  /**
   * Close poll by doc ID
   * @param docId
   * @returns {Promise<void>}
   */
  closePollByDocId (docId) {
    return this.updatePollByDocId(docId, {
      statePoll: poll.CLOSE_POLLS
    })
  }

  /**
   * Propagation poll by doc ID
   * @param docId
   * @returns {Promise<void>}
   */
  propagationPollByDocId (docId) {
    return this.updatePollByDocId(docId, {
      propagation: true
    })
  }

  /**
   * Watcher answers for a poll
   *
   * @param {string} pollId
   * @returns {Query}
   */
  watchAnswersPoll (pollId) {
    return this.getCollection(firebaseChannel.POLL_ANSWER_CHANNEL)
      .where('pollId', '==', pollId)
      .limit(1)
  }

  /**
   * Reset poll to open state (delete all existing users answers)
   * @param docId
   * @returns {Promise<void>}
   */
  resetPollByDocId (docId) {
    return this.getAnswersPoll(docId).then(doc => {
      if (doc.exists) {
        const answers = doc.data().answers.map(answer => {
          return {
            ...answer,
            count: 0,
            average: 0
          }
        })

        doc.ref.update({
          answers
        })

        return this.getCollection(firebaseChannel.POLL_ANSWER_USER_CHANNEL)
          .where('pollAnswerId', '==', doc.id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => doc.ref.delete())
          })
      }
    }).then(() => {
      return this.updatePollByDocId(docId, {
        statePoll: poll.PRE_POLLS
      })
    })
  }

  /**
   * get list answers for a poll
   * @param pollId
   * @returns {*}
   */
  getAnswersPoll (pollId) {
    return this.getCollection(firebaseChannel.POLL_ANSWER_CHANNEL)
      .where('pollId', '==', pollId)
      .limit(1)
      .get()
      .then(querySnapshot => querySnapshot.docs[0])
  }

  /**
   * Get my answer for a poll
   *
   * @param pollId
   * @returns {Query}
   */
  getToHaveAnswerForPoll (pollId) {
    return this.getCollection(firebaseChannel.POLL_ANSWER_USER_CHANNEL)
      .where('pollAnswerId', '==', pollId)
      .where('roomExternalId', '==', this.room.externalId)
      .where('userOwnerExternalId', '==', this.user.externalId)
      .limit(1)
      .get()
      .then(querySnapshot => querySnapshot.size !== 0)
  }

  /**
   * Add answer for a poll
   *
   * @param pollAnswerId
   * @param answers
   * @returns {*}
   */
  addNewAnswerPoll (pollAnswerId, answers) {
    if (this.room.externalId && this.user.externalId) {
      return this.getCollection(firebaseChannel.POLL_ANSWER_USER_CHANNEL)
        .doc(pollAnswerId + '-' + this.user.externalId).set({
          answers,
          pollAnswerId,
          roomExternalId: this.room.externalId,
          userOwnerExternalId: this.user.externalId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp()
        })
    }

    return Promise.reject(new Error('Add room externalId and/or user externalId'))
  }

  /**
   * Add event listener for connexion and disconnection
   * @returns {*}
   */
  eventListenerConnectOrDisconnect ({ onConnect, onDisconnect } = {}) {
    const type = this.user.role === roles.SPECTATOR ? 'spectators' : 'speakers'
    const databaseUser = this.database
      .ref('/rooms/' + this.room.externalId + '/presence/' + type + '/' + this.user.externalId)

    return this.database
      .ref('.info/connected')
      .on('value', snapshot => {
        if (snapshot.val()) {
          if (this.user.role !== roles.SPECTATOR) {
            this.disconnectMeConsole('isLive')
            this.disconnectMeConsole('speakerRequests')
          }

          if (onConnect) {
            onConnect()
          }

          return databaseUser
            .onDisconnect()
            .remove()
            .then(() => databaseUser
              .set({
                externalId: this.user.externalId,
                role: this.user.role,
                firstname: this.user.firstname,
                lastname: this.user.lastname,
                avatar: this.user.avatar
              }))
        } else if (onDisconnect) {
          return onDisconnect()
        }
      })
  }

  /**
   * Getter for spectators audience
   *
   * @returns {*}
   */
  getAudienceSpectators () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/audience/spectators')
  }

  /**
   * Get once online spectators
   *
   * @returns {*}
   */
  getPresenceSpectators () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/presence/spectators')
      .once('value')
  }

  /**
   * Watcher for users status ON-LINE
   * @returns {*}
   */
  watchPresenceSpeakers () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/presence/speakers')
  }

  /**
   * Watch speakers on LIVE
   * @returns {*}
   */
  watchSpeakerOnLive () {
    return this.watchConsole('isLive')
  }

  /**
   * Add speakers on LIVE
   * @param externalId
   * @returns {*}
   */
  addSpeakerOnLive (externalId) {
    return this.setConsole(externalId, 'isLive', true)
  }

  /**
   * Set speakers on live
   * @param externalId
   * @returns {*}
   */
  removeSpeakerOnLive (externalId) {
    return this.removeConsole(externalId, 'isLive')
  }

  /**
   * Watch "speakerRequests" path
   * @returns {*}
   */
  watchSpeakerRequests () {
    return this.watchConsole('speakerRequests')
  }

  /**
   * Add new "speakerRequests" entry for user (only one per user)
   *
   * @param {int} externalId
   * @param {string} type
   * @param {string} status
   * @param {int|null} forcingAuthorExternalId
   * @returns {*}
   */
  addSpeakerRequest (externalId, type, status, forcingAuthorExternalId) {
    return this.setConsole(externalId, 'speakerRequests', {
      type,
      askedBy: externalId,
      askedAt: this.getTimestamp(),
      status,
      forcedBy: forcingAuthorExternalId
    })
  }

  removeSpeakerRequest (externalId) {
    return this.removeConsole(externalId, 'speakerRequests')
  }

  updateSpeakerRequestStatus (askedById, status) {
    return this.database
      .ref(`/rooms/${this.room.externalId}/console/speakerRequests/${askedById}`)
      .transaction(value => {
        // Forced request
        const isForcedRequest = Object.keys(value).indexOf('forcedBy') > -1
        if (this.user.role === roles.SPEAKER && value.status === actions.PENDING && isForcedRequest) {
          value.status = status
          value.statusUpdatedBy = this.user.externalId

          return value
        }

        // Speaker request
        if (this.user.role === roles.MANAGER || (this.user.role === roles.ADMIN && value.status === actions.PENDING)) {
          value.status = status
          value.statusUpdatedBy = this.user.externalId

          return value
        }

        throw new Error('[FireBase] unable to update live request status')
      })
  }

  /**
   * Watch value json for a console
   * @param type
   * @returns {*}
   */
  watchConsole (type) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/console/' + type)
  }

  /**
   * Set value json for a console
   * @param externalId
   * @param type
   * @param data
   * @returns {*}
   */
  setConsole (externalId, type, data) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/console/' + type + '/' + externalId)
      .set(data)
  }

  /**
   * Set current document sharing
   *
   * @param externalId
   * @param type
   * @param documentId
   * @param subDocumentPosition
   * @returns {*}
   */
  setCurrentBroadcastSharing (externalId, type, documentId, subDocumentPosition) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/currentBroadcastSharing')
      .set({
        type,
        documentId,
        subDocumentPosition,
        speakerExternalId: externalId,
        createdAt: Date.now()
      })
  }

  /**
   * Update current document sharing
   *
   * @param documentId
   * @param speakerExternalId
   * @param subDocumentPosition
   * @returns {*}
   */
  updateCurrentBroadcastSharing (documentId, subDocumentPosition, speakerExternalId) {
    const data = { documentId, subDocumentPosition }
    if (speakerExternalId) data.speakerExternalId = speakerExternalId
    return this.database
      .ref('/rooms/' + this.room.externalId + '/currentBroadcastSharing')
      .update(data)
  }

  /**
   * Watch current document sharing
   * @returns {*}
   */
  watchCurrentBroadcastSharing () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/currentBroadcastSharing')
  }

  /**
   * remove current document sharing
   * @returns {*}
   */
  removeCurrentBroadcastSharing () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/currentBroadcastSharing')
      .remove()
  }

  /**
   * remove value json for a console
   * @param externalId
   * @param type
   * @returns {*}
   */
  removeConsole (externalId, type) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/console/' + type + '/' + externalId)
      .remove()
  }

  /**
   * Disconnect me console
   * @param type
   * @returns {*}
   */
  disconnectMeConsole (type) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/console/' + type + '/' + this.user.externalId)
      .onDisconnect()
      .remove()
  }

  /**
   * Prepare query for documents snapshot watching
   */
  watchDocuments (onlyDownloadable) {
    let query = this.getCollection(firebaseChannel.DOCUMENTS_CHANNEL).where('roomExternalId', '==', this.room.externalId)
    if (onlyDownloadable) {
      query = query.where('downloadable', '==', true)
    }
    query = query.orderBy('createdAt', 'desc')
    return query
  }

  /**
   * Watcher for documents downloadable
   * @returns {*}
   */
  watchDocumentsDownloadable () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/documents')
  }

  /**
   * Prepare query for documents snapshot watching
   */
  watchBroadcastedDocument () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/currentBroadcastSharing/')
  }

  /**
   * Watcher for room status
   * @returns {*}
   */
  watchRoomStatus () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/status')
  }

  /**
   * Watcher for room status
   * @returns {*}
   */
  updateRoomStatus (status) {
    return this.database
      .ref('/rooms/' + this.room.externalId)
      .update({
        status: status
      })
  }

  /**
   * update header in store
   * @param {Object} header
   */
  updateHeader (header) {
    return this.database
      .ref('/rooms/' + this.room.externalId)
      .update({
        header: header
      })
  }

  /**
   * get header
   * @returns {*}
   */
  getHeader () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/header')
  }

  /**
   * get header started at
   * @returns {*}
   */
  getHeaderStartedAt () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/header/startedAt')
  }

  /**
   * get header status
   * @returns {*}
   */
  getHeaderStatus () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/header/status')
  }

  /**
   * get header slots duration
   * @returns {*}
   */
  getHeaderSlotsDuration () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/header/slotsDuration')
  }

  /**
   * get header slots count
   * @returns {*}
   */
  getHeaderSlotsCount () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/header/slotsCount')
  }

  /**
   * add header slot
   * @param {Object} slot
   */
  addHeaderSlot (slot) {
    this.database
      .ref('/rooms/' + this.room.externalId + '/header/slots').update(slot)
  }

  /**
   * update blacklist
   *
   * @param spectator
   * @param isBlacklist
   * @returns {Promise<any>|*}
   */
  updateBlacklistSpectator (spectator, isBlacklist) {
    if (isBlacklist === true) {
      return this.database
        .ref('/rooms/' + this.room.externalId + '/blacklist/' + spectator.externalId)
        .set({
          externalId: spectator.externalId,
          firstname: spectator.firstname,
          lastname: spectator.lastname,
          updatedAt: this.getTimestamp()
        })
    } else {
      return this.database
        .ref('/rooms/' + this.room.externalId + '/blacklist/' + spectator.externalId)
        .set(null)
    }
  }

  /**
   * watch blacklist for spectator
   *
   * @param spectatorId
   * @returns {*}
   */
  watchBlackListOnSpectatorByType (spectatorId) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/blacklist/' + spectatorId)
  }

  /**
   * Getter blacklist spectators
   *
   * @returns {*}
   */
  getBlacklistSpeactors () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/blacklist')
      .once('value')
  }

  /**
   * @param {string} channelName
   */
  setPublicChatStartDate (channelName) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/publicChatStartAt')
      .set(this.getTimestamp())
  }

  /**
   * @param {string} channelName
   */
  watchPublicChatStartAt (channelName) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/publicChatStartAt')
  }

  /**
   * Delete chat message by doc ID
   * @param {string} channelName
   * @param {string} docId
   * @param {string} status
   * @returns {Promise<void>}
   */
  updateChatMessageStatusByDocId (channelName, docId, status) {
    return this.getCollection(channelName)
      .doc(docId)
      .update({
        status,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }

  /**
   * Add chat message to favorite by doc ID
   * @param {string} channelName
   * @param {string} docId
   * @param {boolean} isFavorite
   * @returns {Promise<void>}
   */
  updateChatMessageIsFavorite (channelName, docId, isFavorite) {
    return this.getCollection(channelName)
      .doc(docId)
      .update({
        isFavorite,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }

  /**
   * Add chat message to answered by doc ID
   *
   * @param {string} channelName
   * @param {string} docId
   * @param {boolean} isAnswered
   * @returns {Promise<void>}
   */
  updateChatMessageIsAnswered (channelName, docId, isAnswered) {
    return this.getCollection(channelName)
      .doc(docId)
      .update({
        isAnswered,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      })
  }

  /**
   * get recording
   * @returns {*}
   */
  updateRecording (action) {
    return this.database
      .ref('/rooms/' + this.room.externalId)
      .update({
        recording: action
      })
  }

  /**
   * watch recording
   * @returns {*}
   */
  watchRecording () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/recording')
  }

  /**
   * Set timestamp for last edit profile
   * @returns {*}
   */
  updateLastEditProfile () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/updateSpeakersProfiles')
      .set(this.getTimestamp())
  }

  /**
   * watch recording
   * @returns {*}
   */
  watchUpdateLastEditProfile () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/updateSpeakersProfiles')
  }

  /**
   * watch if the room has had its first stream
   *
   * @returns {*}
   */
  watchFirstStream () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/firstStream')
  }

  /**
   * @returns {*}
   */
  updateFirstStream () {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/firstStream')
      .set(this.getTimestamp())
  }

  /**
   * Save the last position of document displayed
   *
   * @param {Object} broadcastedDocument
   * @param {int} position
   *
   * @returns {*}
   */
  saveLastDocumentPosition (documentId, position) {
    return this.database
      .ref('/rooms/' + this.room.externalId + '/lastDocumentsPosition/' + documentId)
      .set({
        position,
        createdAt: Date.now()
      })
  }

  /**
   * Ref to lastDocumentsPosition
   *
   * @param {Object} broadcastedDocument
   *
   * @returns {*}
   */
  lastDocumentsPosition () {
    return this.database.ref('/rooms/' + this.room.externalId + '/lastDocumentsPosition/')
  }
}

export const firebaseSingleton = new FireBase()