import enums from 'plugins/enums'

export default function (time) {
  // Hours, minutes and seconds
  let hrs = Math.floor(time / enums.math.MATH_HOUR)
  let mins = Math.floor((time % enums.math.MATH_HOUR) / enums.math.MATH_SECOND)
  let secs = Math.floor(time % enums.math.MATH_SECOND)
  let format = ''
  if (hrs > 0) {
    format += '' + hrs + ':'
  }
  if (mins < 10) {
    mins = '0' + mins
  }
  if (secs < 10) {
    secs = '0' + secs
  }
  format += mins + ':' + secs

  return format
}
