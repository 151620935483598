import pages from 'plugins/declarations/pages'

export default {
  namespaced: true,
  state: {
    current: null,
    botTeam: [
      pages.CONSOLE
    ]
  },
  mutations: {
    setCurrent (state, current) {
      state.current = current
    }
  }
}
