export default Object.freeze({
  CONSOLE: 'console',
  LIVE: 'live',
  REPLAY: 'replay',
  ERROR: 'error',
  ERROR_403: 'error/403',
  ERROR_404: 'error/404',
  PREVIEW: 'preview',
  SYSTEM_CHECK: 'system_check'
})
