import endpoints from 'plugins/declarations/endpoints'
import Request from 'class/Request'

export default {
  namespaced: true,
  state: {
    url: null
  },
  mutations: {
    updateReplay (state, data) {
      if (data.url) {
        state.url = data.url
      }
    }
  },
  actions: {
    get (context, roomId) {
      return Request
        .get(endpoints.BROADCAST_REPLAY_URL.replace('{{id}}', roomId))
        .then((response) => {
          // Save data
          context.commit('updateReplay', response.data.data)
          return response
        })
    }
  }
}
