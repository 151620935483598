import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist/dist/umd'
import enums from 'plugins/enums'

import log from './modules/log'
import session from './modules/session'
import room from './modules/room'
import documents from './modules/documents'
import user from './modules/user'
import parameters from './modules/parameters'
import team from './modules/team'
import tokbox from './modules/tokbox'
import replay from './modules/replay'
import poll from './modules/poll'
import notifications from './modules/notifications'
import chat from './modules/chat'
import audience from './modules/audience'
import route from './modules/route'
import control from './modules/control'
import actions from './modules/actions'
import broadcast from './modules/broadcast'
import systemcheck from './modules/systemcheck'
import recording from './modules/recording'
import ApiLogs from './modules/ApiLogs'
import StreamHealth from './modules/StreamHealth'

Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
  strictMode: import.meta.env.VITE_NODE_ENV !== 'production',
  storage: sessionStorage,
  modules: [
    'room',
    'session',
    'user'
  ]
})

export default new Vuex.Store({
  modules: {
    log,
    session,
    room,
    documents,
    user,
    parameters,
    team,
    tokbox,
    replay,
    poll,
    notifications,
    chat,
    audience,
    route,
    control,
    actions,
    broadcast,
    systemcheck,
    recording,
    ApiLogs,
    StreamHealth
  },
  state: {
    isIE: null
  },
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    checkIsIE (state, forced) {
      if (state.isIE === null) {
        if (forced !== undefined) {
          state.isIE = forced
        } else {
          state.isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0
        }
      }
    }
  },
  getters: {
    hasManagerOnline: (state, getters) => () => {
      const managers = state.team.speakers.filter((u) => {
        return u.role === enums.roles.MANAGER && (state.actions.online.indexOf(String(u.externalId)) !== -1)
      })

      return managers.length !== 0
    },
    isIE: (state) => {
      return state.isIE
    }
  },
  strict: import.meta.env.VITE_NODE_ENV !== 'production',
  plugins: [vuexPersist.plugin]
})
