import Request from 'class/Request'
import endpoints from 'plugins/declarations/endpoints'

export default {
  namespaced: true,
  actions: {
    qualityIssue (context, { roomId, stats }) {
      return Request.post(endpoints.LOGS.replace('{{id}}', roomId), { stats, type: 'user.quality.issueuser.quality.audiofallback' })
    },
    audioFallback (context, { roomId }) {
      return Request.post(endpoints.LOGS.replace('{{id}}', roomId), { stats: {}, type: 'user.quality.audiofallback' })
    }
  }
}
